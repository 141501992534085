import React from "react"
import Writing from "../../images/header-text/writing.svg"
import "./hero.less"

const Hero = () => {
  return (
    <section className="hero-section">
      <div className="hero">
        <Writing />
      </div>
    </section>
  )
}

export default Hero
