import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Hero from "../components/hero/hero"
import PostList from "../components/postList/postList"

const BlogIndexPage = ({
  data: {
    site: { siteMetadata: { title } = {} },
  },
}) => {
  return (
    <Layout title={title} secondaryHeader>
      <Hero />
      <PostList />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default BlogIndexPage
